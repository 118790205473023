<template>
  <div class="footer-links">
    <router-link :to="{ name: 'TransferPolicy' }">運送政策</router-link>
    <router-link :to="{ name: 'ExchangePolicy' }">退換貨政策</router-link>
    <router-link :to="{ name: 'DataPolicy' }">隱私權保護政策</router-link>
  </div>
</template>

<script>
export default {
  name: 'FooterLinks',
  data() {
    return {

    };
  },
}
</script>

<style lang="scss" scoped>
.footer-links {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  background-color: #fff;
  border: solid 1px var(--main-dark);
  border-radius: 999px;
  padding: 1rem;
  
  >a {
    font-weight: 1000;
    color: var(--main-dark);
    display: block;
    flex: 0 0 100%;
    text-align: center;
    letter-spacing: .1em;
    padding: .5rem 1rem;
    &:hover, &:active {
      text-decoration: none;
    }
  }

  @media screen and (min-width: 768px) {
    >a {
      padding: .5rem 1.5rem;
      flex: 0 0 auto;
    }
  }
}
</style>