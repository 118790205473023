import Vue from 'vue'
import VueRouter from 'vue-router'
import store from '@/store';

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Home',
    redirect: '/special/moonfes2024',
    component: () => import(/* webpackChunkName: "homeView" */ '../views/HomeView.vue')
  },
  {
    path: '/transferPolicy',
    name: 'TransferPolicy',
    component: () => import(/* webpackChunkName: "transferPolicy" */ '../views/policies/TransferPolicy.vue')
  },
  {
    path: '/exchangePolicy',
    name: 'ExchangePolicy',
    component: () => import(/* webpackChunkName: "exchangePolicy" */ '../views/policies/ExchangePolicy.vue')
  },
  {
    path: '/dataPolicy',
    name: 'DataPolicy',
    component: () => import(/* webpackChunkName: "dataPolicy" */ '../views/policies/DataPolicy.vue')
  },
  {
    path: '/order',
    name: 'AllProduct',
    meta: {
      hideNav: false,
    },
    component: () => import(/* webpackChunkName: "allProduct" */ '../views/product/ProductList.vue')
  },
  {
    path: '/product/:serial',
    name: 'ProductDetail',
    component: () => import(/* webpackChunkName: "ProductDetail" */ '../views/product/ProductDetail.vue'),
  },
  
  {
    path: '/about',
    name: 'AboutPage',
    redirect: '/about/booth',
    meta: {
    },
    component: () => import(/* webpackChunkName: "AboutPage" */ '../views/about/AboutPage.vue'),
    children: [
      {
        path: 'baby',
        name: 'AboutBaby',
        meta: {
          noToTop: true,
        },
        component: () => import(/* webpackChunkName: "AboutBaby" */ '../views/about/AboutBaby.vue'),
      },
      {
        path: 'booth',
        name: 'AboutBooth',
        meta: {
          noToTop: true,
        },
        component: () => import(/* webpackChunkName: "AboutBooth" */ '../views/about/AboutBooth.vue'),
      },
      {
        path: 'customize',
        name: 'AboutCustom',
        meta: {
          noToTop: true,
        },
        component: () => import(/* webpackChunkName: "AboutCustom" */ '../views/about/AboutCustom.vue'),
      },
    ],
  },
  {
    path: '/cart',
    name: 'Cart',
    meta: {
      hideNav: false,
    },
    component: () => import(/* webpackChunkName: "Cart" */ '../views/cart/CartDetail.vue')
  },
  {
    path: '/comingsoon',
    name: 'ComingSoon',
    meta: {
      hideNav: false,
    },
    component: () => import(/* webpackChunkName: "ComingSoon" */ '../views/ComingSoon.vue')
  },
    
  /* 特殊節慶系列 */
  {
    path: '/special/moonfes2024',
    name: 'MoonFes2024',
    meta: {
      hideNav: true,
    },
    component: () => import(/* webpackChunkName: "MoonFes2024" */ '../views/special/MoonFes2024.vue')
  },
  {
    path: '/special/moonfes2024thanks',
    name: 'MoonFes2024Thanks',
    meta: {
      hideNav: true,
    },
    component: () => import(/* webpackChunkName: "MoonFes2024Thanks" */ '../views/special/MoonFes2024Thanks.vue')
  },
  {
    path: '/special/newyear2024',
    name: 'NewYear2024',
    component: () => import(/* webpackChunkName: "NewYear2024" */ '../views/special/NewYear2024.vue')
  },
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
});

router.afterEach(async(to) => {
  // console.log(`${JSON.stringify(to.meta)}`);
  let hideNav = false;
  if (to.meta.hideNav) {
    hideNav = true;
  }
  store.commit('SETNAVHIDE', hideNav);
});

export default router
