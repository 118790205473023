<template>
  <router-link :to="{name: 'Home'}" class="logo-badge">
    <img :src="require('@/assets/img/logo_blue.png')" alt="">
    <p>
      <span style="font-style: italic;">L'TAS Pâtisserie</span><br/>
      拉塔斯點心鋪
    </p>
  </router-link>
</template>

<script>
export default {
  name: 'LogoBadge',
}
</script>

<style lang="scss" scoped>
.logo-badge {
  display: flex;
  background-color: #fff;
  padding: .8rem 1rem 1.25rem 1.5rem;
  border-bottom-left-radius: 2rem;
  border-bottom-right-radius: 2rem;
  column-gap: .75rem;
  border: solid 1px #D6D6D6;
  border-top: none;
  align-items: center;
  &:active, &:hover {
    text-decoration: none;
  }
  >img {
    width: 45px;
  }
  >p {
    margin: 0;
    font-size: .8rem;
    color: initial;
    font-weight: 600;
  }
}

@media screen and (min-width: 768px) {
  .logo-badge {
    column-gap: 1.5rem;
    padding: 1.5rem 3rem 1.5rem 3rem;
    >img {
      width: 100px;
    }
    >p {
      letter-spacing: .2em;
      font-size: 1.2rem;
      color: initial;
    }
  }
}
</style>